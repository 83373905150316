import React from 'react';

import './Contact.css';

import { TelFooter } from '../../components/FiveScreen/Contact';

function FooterH34() {
  return (
    <div className="footerH34">
      <h4>Wladek Clean</h4>
      <h3>Furniture cleaning</h3>
    </div>
  );
}

function Contact() {
  return (
    <div className="contact">
      <FooterH34 />
      <TelFooter />
    </div>
  );
}

export default Contact;
