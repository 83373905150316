import './Service.css';
import foto1 from '../../components/img/HopClean/Service/clean_sofa6.jpg';
import foto2 from '../../components/img/HopClean/Service/clean_sofa4.jpg';
import foto3 from '../../components/img/HopClean/Service/martrass.jpg';
function Service() {
  return (
    <div className="service__items">
      <div className="service-item">
        <h2 className="service-h2"> Pranie kanap</h2>
        <div className="main__services-img">
          <img src={foto1} alt="pranie kanap" />
        </div>
        <p className="main-services-price"> od 100 zł</p>
      </div>
      <div className="service-item">
        <h2 className="service-h2"> Pranie materaców</h2>
        <div className="main__services-img">
          <img src={foto3} alt="pranie materacow" />
        </div>
        <p className="main-services-price"> od 50 zł</p>
      </div>
      <div className="service-item">
        <h2 className="service-h2"> Pranie narożników </h2>
        <div className="main__services-img">
          <img src={foto2} alt="prinie naroznikow" />
        </div>
        <p className="main-services-price"> od 150 zł</p>
      </div>
    </div>
  );
}
export default Service;
