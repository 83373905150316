import React from 'react';
import Phone2 from './../img/phone2.png';
import './Contact.css';
import { Link } from 'react-router-dom';
import facebook from '../img/HopClean/facebook.png';
// import youtube from '../img/HopClean/youtube.png';
import instargram from '../img/HopClean/instagram.png';
import ticktock from '../img/HopClean/tiktok.png';

function FooterH34() {
  return (
    <div className="footerH34">
      <h4>Wladek Clean</h4>
      <h3>Czyszczenie mebli</h3>
    </div>
  );
}
export function TelFooter() {
  return (
    <div className="telFooter">
      <Link className="phoneFooter" to="tel:+48513313818">
        {' '}
        <img src={Phone2} alt="no painting" /> <span>+48 513 313 818</span>
      </Link>
      <div className="linksFooter">
        <Link to="#" target="_blanket">
          <img src={facebook} alt="facebook" title="facebook" />
        </Link>
        {/* <Link to="#" target="_blanket">
          <img src={youtube} alt="youtube" title="youtube" />
        </Link> */}
        <Link to="#" target="_blanket">
          <img src={instargram} alt="instagram" title="instagram" />
        </Link>
        <Link to="#" target="_blanket">
          <img src={ticktock} alt="ticktock" title="ticktock" />
        </Link>
      </div>
    </div>
  );
}
function Contact() {
  return (
    <div className="contact">
      <FooterH34 />
      <TelFooter />
    </div>
  );
}

export default Contact;
