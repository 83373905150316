import React, { useState } from 'react';
import './TelSubmit.css';
import BackDropForm from './BackDropForm';

import { Social, Tel, Planwork } from '../../components/FirstScreen/TelSubmit';

export function PlanSocial() {
  return (
    <div className="socialplanwork">
      <Planwork />
      <Tel />
    </div>
  );
}

function Submit({ onOpen = (f) => f }) {
  return (
    <div className="submit">
      <button
        onClick={() => {
          onOpen(true);
        }}
        id="btn"
        className="reg_btn hidden"
      >
        Zostaw prośbę
      </button>
    </div>
  );
}

function TelSubmit() {
  const [isSignINBackdrop, setSignInBackdrop] = useState(false);

  if (isSignINBackdrop) {
    document.querySelector('body').style.overflow = 'hidden';
  } else {
    document.querySelector('body').style.overflow = 'visible';
  }

  const onOpenBackdrop = () => {
    setSignInBackdrop(true);
  };
  return (
    <>
      {/* <div className="telSubmit"> */}
      <PlanSocial />
      <Submit onOpen={onOpenBackdrop} />
      <BackDropForm
        setOpen={() => setSignInBackdrop(false)}
        isOpen={isSignINBackdrop}
        handleClick={() => setSignInBackdrop(false)}
      />
      {/* </div> */}
    </>
  );
}

function LangTel() {
  const [isScrollUp, setScrollUp] = useState(false);

  window.addEventListener('scroll', function () {
    let scrolled = window.pageYOffset;
    if (scrolled > 170) {
      setScrollUp(true);
    }
    if (scrolled < 170) {
      setScrollUp(false);
    }
  });

  return (
    <>
      <div className={isScrollUp ? 'fixedlangTel' : 'langTel'}>
        {/* <Social /> */}
        <Social />
        <TelSubmit />
      </div>
    </>
  );
}

export default LangTel;
