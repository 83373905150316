import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import whatsapp from '../img/HopClean/whatsapptitle.png';

function Footer() {
  return (
    <>
      <div className="whatssuptitle">
        <Link to="https://wa.me/48513313818">
          <img src={whatsapp} alt="WhatsApp" title="WhatsApp" />{' '}
        </Link>
      </div>
      <div className="footer">
        <span>Furniture cleaning | Wladek clean | &copy; 2024</span>
      </div>
    </>
  );
}

export default Footer;
