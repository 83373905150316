import React from 'react';
import './General_img.css';
import Foto9 from '../img/HopClean/articel.jpg';
import Furnitura3 from '../img/HopClean/articel2.jpg';
import { Link } from 'react-router-dom';

function Block() {
  return (
    <div className="block">
      <h3>Dlaczego potrzebne prać tapicerkę</h3>
    </div>
  );
}
function BlockOneImgP() {
  return (
    <div>
      <img src={Foto9} alt="foto9" className="article1" />
      <p className="special">
        Pranie mebli tapicerowanych to jedna z ważniejszych czynności związanych
        z utrzymaniem higieny w naszych domach czy miejscach pracy. Meble
        tapicerowane są nie tylko miejscem do wypoczynku, ale także często służą
        jako powierzchnia do pracy czy jedzenia...{' '}
      </p>
    </div>
  );
}
function BlockOneCheck() {
  return (
    <div className="check">
      <Link to="/articelone">Szczegółowo...</Link>
    </div>
  );
}
function BlockOneFromGeneral() {
  return (
    <div className="block_1">
      <Block />
      <BlockOneImgP />
      <BlockOneCheck />
    </div>
  );
}
function BlockTwo() {
  return (
    <div className="block">
      <h3>Pranie materacy Sosnowiec </h3>
    </div>
  );
}
function BlockTwoImgP() {
  return (
    <div>
      <img src={Furnitura3} alt="foto9" />
      <p className="special">
        Materac jest jednym z najważniejszych elementów naszej sypialni, a
        zarazem miejscem, w którym spędzamy około jednej trzeciej naszego życia.
        Dlatego ważne jest, aby nasz materac był czysty i higieniczny...
      </p>
    </div>
  );
}
function BlockTwoCheck() {
  return (
    <div className="check">
      <Link to="/articeltwo">Szczegółowo...</Link>
    </div>
  );
}
function BlockTwoFromGeneral() {
  return (
    <div className="block_2">
      <BlockTwo />
      <BlockTwoImgP />
      <BlockTwoCheck />
    </div>
  );
}
function GeneralImg() {
  return (
    <div className="general_img">
      <BlockOneFromGeneral />
      <BlockTwoFromGeneral />
    </div>
  );
}

export default GeneralImg;
