import React, { useState } from 'react';
import FirstScreen from '../FirstScreen/ContainerOne';
import FiveSCreen from '../FiveScreen/FiveScreen';
import '../Pages/Services.css';
import onas from '../img/HopClean/onas2.jpg';

import '../FirstScreen/Lang.css';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Lang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div className="lang">
        <button onClick={changeLang} className="select">
          <span>Pl</span>{' '}
          <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />{' '}
        </button>
        <button className={isLang ? 'en' : 'hidden'}>
          {isLang ? (
            <Link to="/en/o-nas">En</Link>
          ) : (
            <Link to="/o-nas">Pl</Link>
          )}{' '}
        </button>
      </div>
    </>
  );
}

function Onastext() {
  return (
    <div className="containerOnas">
      <h2>O NAS</h2>
      <p className="onasP">
        Drodzy klienci, jesteśmy firmą sprzątającą "Wladek Clean".
      </p>
      <p>
        Firma zajmująca się czyszczeniem mebli to niezwykle ważne miejsce na
        rynku, ponieważ oferuje ona usługi, które są niezbędne w codziennym
        życiu. Czyszczenie mebli to zadanie, które może być trudne do wykonania
        w domu, szczególnie gdy mamy do czynienia z poważnymi zabrudzeniami lub
        niebezpiecznymi substancjami. Dlatego warto skorzystać z usług
        profesjonalistów, którzy wiedzą, jak poradzić sobie z każdym typem mebla
        i zapewnić im odpowiednie oczyszczenie.
      </p>
      <p>
        Nasza firma zajmuje się profesjonalnym czyszczeniem mebli
        tapicerowanych, w tym sof, krzeseł, foteli, łóżek i innych mebli
        tapicerowanych. Nasze usługi są skierowane zarówno do klientów
        indywidualnych, jak i firm, które potrzebują usług czyszczenia mebli w
        biurach, hotelach, restauracjach i innych miejscach publicznych.
      </p>
      <img src={onas} alt="o nas" />
      <p>
        Jako firma z wieloletnim doświadczeniem w branży, stosujemy tylko
        najwyższej jakości narzędzia i środki czyszczące, aby zapewnić skuteczne
        i bezpieczne czyszczenie. Nasza praca opiera się na indywidualnym
        podejściu do każdego zlecenia, aby zapewnić najlepsze wyniki dla każdego
        mebla i klienta.
      </p>
      <p>
        Nasza firma oferuje szeroką gamę usług, takich jak czyszczenie
        powierzchni, usuwanie plam, usuwanie nieprzyjemnych zapachów,
        dezynfekcja, odkurzanie i wiele innych. Każda usługa jest dostosowana do
        indywidualnych potrzeb klienta, aby zapewnić jak najlepsze efekty.
      </p>
      <p>
        Czyszczenie mebli tapicerowanych może być kosztowne, ale nasza firma
        oferuje konkurencyjne ceny, które są uzależnione od wielkości i rodzaju
        mebla. Nasze ceny są uczciwe i przejrzyste, co oznacza, że ​​klienci nie
        muszą obawiać się ukrytych kosztów.
      </p>
      <p>
        Współpracujemy z renomowanymi producentami mebli, dzięki czemu mamy
        wiedzę na temat różnych rodzajów materiałów, z których wykonane są
        meble, i najlepszych sposobów czyszczenia ich. Ponadto, nasi pracownicy
        są szkoleni w zakresie najnowszych metod i technik czyszczenia, aby
        zapewnić najlepsze wyniki dla naszych klientów.
      </p>
      <p>
        Jako firma zajmująca się czyszczeniem mebli, jesteśmy zobowiązani do
        zapewnienia naszym klientom czystości, bezpieczeństwa i zadowolenia z
        naszych usług.
      </p>
    </div>
  );
}

function Onas() {
  return (
    <div className="body_wrapper">
      <FirstScreen />
      <Lang />
      <Onastext />
      <FiveSCreen />
    </div>
  );
}
export default Onas;
